.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.officer-img {
  width: 300px;
  height: 300px;
}

.photo-gallery {
  width: 400px;
  height: 400px;
}

.dark-bg {
  background-color: #41525c;
  border-radius: 25px;
  border: 3px solid #819dc2;
}
.light-bg{
  background-color: #819dc2;
  border-radius: 25px;
  border: 3px solid #161630;
  color: #161630;
}

.center, .center-column, .top, .right, .bottom, .left {
	display: flex;
	justify-content: center;
	align-items: center;
}
.border{
  border-radius: 25px;
  border: 3px solid #819dc2;
  
}




.txt-light{
  color: #819dc2;
}
.txt-blk{
  color: #161630;
}
.txt-dark {
  color: #41525c;
}
.txt-dark:hover{
  color: white;
}
.txt-light:hover{
  color: white;
}

.mobile-nav-blk{
  color: #161630;
}
.mobile-nav-blk:hover{
  color: #819dc2;
}
/* 
.background {
  background-image: url(data:./assets/images/background.png);
  background-size: cover;
} */

.half {
  width: 48%;
}

.fourty {
  width: 40%;
}

.thirty {
  width: 30%;
  /* width: 400px; */
}
.ninetyNine {
  width: 99%;
}

.wide-box {
  width: 375px;
  height: 500px;
}
.wider-box {
  width: 400px;
  height: 390px;
}
.widers-box {
  width: 450px;
  height: 390px;
}
.tall-banner {
  height: 175px;
}

.video-container{
  position: relative;
  width: 100%;
  padding-bottom: 45%;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.navbar-background-color{
  background-color: #161630
}

@media only screen and (max-width: 1100px) {
  .half {
    width: 98%;
  }

  .fourty {
    width: 95%;
  }

  .thirty {
    width: 90%;
  }
  .tall-banner {
    height: 200px;
  }
}

@media only screen and (max-width: 750px) {

  .tall-banner {
    height: 250px;
  }
}

button:hover {
  cursor: pointer;
  letter-spacing: 1px;

}
